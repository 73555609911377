<!--
App Icon

Add all icons here for central management.
Icons take props for:
 - color (the colour for the icon)
 - icon - (the given name for the icon - not the icons8 name)
 - size - (the size for the icon)
-->
<template>
	<div>
		<div v-for="item in icons" :key="item.name+item.reference">

			<v-icon v-if="icon === item.name"
					:class="item.reference"
					:color="color"
					:size="size"/>

		</div>
	</div>
</template>

<script>
export default {

	name: "AppIcon",

	props: ['color', 'icon', 'size'],

	data: () => ({
		icons: [
			{name: 'check', reference: 'icons8-done'},
			{name: 'close', reference: 'icons8-close'},
			{name: 'closedEye', reference: 'icons8-closed-eye'},
			{name: 'computer', reference: 'icons8-laptop-search'},
			{name: 'email', reference: 'icons8-email-sign'},
			{name: 'error', reference: 'icons8-error'},
			{name: 'eye', reference: 'icons8-eye'},
			{name: 'help', reference: 'icons8-help'},
			{name: 'home', reference: 'icons8-home'},
			{name: 'logIn', reference: 'icons8-login'},
			{name: 'logOut', reference: 'icons8-logout-rounded'},
			{name: 'password', reference: 'icons8-password'},
			{name: 'people', reference: 'icons8-business-group'},
			{name: 'person', reference: 'icons8-employee'},
			{name: 'qrCode', reference: 'icons8-qr-code'},
			{name: 'settings', reference: 'icons8-settings'},
		]
	})

}
</script>

<style scoped>

</style>
