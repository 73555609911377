import Vue from 'vue'
import VueRouter from 'vue-router'

// Views
import Home from '@/views/home/Home.vue'
import UserType from '@/views/userType/UserType.vue'
import SwappMethod from '@/views/swappMethod/SwappMethod.vue'
import VisitorRegistration from '@/views/visitorRegistration/VisitorRegistration.vue'
import VisitorSignOut from '@/views/visitorSignOut/VisitorSignOut.vue'
import ResetPassword from '@/views/resetPassword/ResetPassword.vue'

import Manual from '@/views/manual/Manual.vue'
import Reverse from '@/views/reverse/QrCodeReader.vue'
import Settings from '@/views/settings/Settings.vue'
import SwappResult from '@/views/swappResult/SwappResult.vue'
import SwappDirection from '@/views/swappDirection/SwappDirection.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/userType',
		name: 'UserType',
		component: UserType
	},
	{
		path: '/swappMethod',
		name: 'SwappMethod',
		component: SwappMethod
	},
	{
		path: '/swappDirection',
		name: 'SwappDirection',
		component: SwappDirection
	},
	{
		path: '/manual',
		name: 'Manual',
		component: Manual
	},
	{
		path: '/reverse',
		name: 'Reverse',
		component: Reverse
	},
	{
		path: '/resetPassword',
		name: 'ResetPassword',
		component: ResetPassword
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings
	},
	{
		path: '/swappResult',
		name: 'SwappResult',
		component: SwappResult
	},
	{
		path: '/visitorRegistration',
		name: 'VisitorRegistration',
		component: VisitorRegistration
	},
	{
		path: '/visitorSignOut',
		name: 'VisitorSignOut',
		component: VisitorSignOut
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
