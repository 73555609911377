<template>
    <div class="d-flex flex-column align-center pa-4" style="height: calc(100vh - 64px)">
        <page-title class="mt-4" title="Thanks for Visiting" />

        <app-text class="d-flex justify-center mt-8" size="normal"> Select your name from the list below to sign out. </app-text>

        <v-divider class="mt-4" style="width: 100%" />

        <!--Visitors Selection-->
        <div class="mt-4" style="width: 100%">
            <!--UserName-->
            <v-autocomplete
                class="rounded-lg"
                color="primary"
                flat
                hide-details="auto"
                item-color="primary"
                :items="visitorsCollectionData"
                item-text="userName"
                label="Select Your Name"
                no-data-text="There are no visitors to sign out."
                outlined
                :return-object="true"
                solo
                v-model="selectedUserData"
            >
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <app-text>{{ item.userName }}</app-text>
                        <app-text color="grey" size="small">{{ item.userCompany }}</app-text>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </div>

        <!--Visitor Details-->
        <div v-if="selectedUserData?.id" class="mt-4" style="width: 100%">
            <div class="d-flex align-center">
                <app-text size="small" class="text-right mr-4" color="grey9" style="width: 80px">Name:</app-text>
                <app-text>{{ selectedUserData.userName }}</app-text>
            </div>

            <div class="d-flex align-center">
                <app-text size="small" class="text-right mr-4" color="grey9" style="width: 80px">Company:</app-text>
                <app-text>{{ selectedUserData.userCompany }}</app-text>
            </div>

            <div class="d-flex align-center">
                <app-text size="small" class="text-right mr-4" color="grey9" style="width: 80px">Car Reg:</app-text>
                <app-text>{{ selectedUserData.userCarRegistrationNumber }}</app-text>
            </div>
        </div>

        <v-spacer />

        <div style="width: 100%">
            <app-btn @click.native="handleBack" :block="true" class="mt-4" color="grey" label="Back" />
        </div>
        <!--Sign Out Button-->
        <div style="width: 100%">
            <app-btn @click.native="handleSignOut" :block="true" class="mt-4" color="green" label="Sign Out" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'VisitorSignOut',

    data: () => ({
        form: {
            id: '',
            userName: '',
            userCompany: '',
            userCarRegistrationNumber: '',
            userPersonVisiting: '',

            privacyPolicyConsent: false,
            userCanUsePersonalDetails: true,
            userStatus: 'APPROVED',
            userType: 'Visitor',
            userRole: 'User',
            userLevel: 'Visitor-User',

            lastSite: {},
            lastLocation: {},
            swappMethod: 'Reverse',
            swappStatus: 0,
            swappTime: ''
        },
        selectedUserData: {},
        visitorsCollectionData: []
    }),

    methods: {
        /**
         * Get Collection Data
         *
         * Fetch the data from the collection and assign them to the class state.
         *
         * @returns {Promise<void>}
         */
        async getVisitorsCollectionData() {
            const t = this
            let collectionData = []

            await t.$firebase.db
                .collection('visitors')
                .where('swappStatus', '==', 1)
                .onSnapshot((snapshot) => {
                    // Rest array to avoid data duplication
                    collectionData = []

                    snapshot.forEach((doc) => {
                        const DOCUMENT = doc.data()
                        DOCUMENT.id = doc.id

                        if (!DOCUMENT.hasOwnProperty('delete')) collectionData.push(DOCUMENT)
                    })

                    t.visitorsCollectionData = collectionData.sort((a, b) => (a.userName > b.userName ? 1 : -1))
                })
        },

        handleBack() {
            const t = this

            t.MIX_go('SwappDirection')
        },
        /**
         * Handle Sign Out
         *
         * Handle the sign out process by calling the updateDocument method.
         *
         * @returns {Promise<void>}
         */
        async handleSignOut() {
            const t = this
            const SELECTED_USER_DATA = t.selectedUserData

            await t.updateDocument(SELECTED_USER_DATA)
        },

        /**
         * SWAPP User
         *
         * SWAPP the current user with their ID and swappDirection.
         *
         * @returns {Promise<void>}
         * @param userData the user data to SWAPP
         */
        async swappUser(userData) {
            const t = this

            // Convert the word-based direction for the 1/0 database vale
            let swappDirection = t.MIX_getFromLocalStorage('swappDirection')
            swappDirection = swappDirection.toUpperCase() === 'IN' ? 1 : 0

            const RESPONSE = await t.MIX_swapp(userData.id, userData.userType, swappDirection)

            // Set the params with the SWAPP result (true or false), and go to the SWAPP Result page
            t.MIX_go('SwappResult', { hasErrors: RESPONSE.hasErrors })
        },

        /**
         * Update Document
         *
         * Update the document with the new form data.
         *
         * @returns {Promise<void>}
         */
        async updateDocument(userData) {
            const t = this

            const RESPONSE = await t.MIX_firestore_updateDocumentFields('visitors', userData.id, userData)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error updating document: ', RESPONSE.error)
                t.handleError('errorUpdatingDoc')
                return
            }

            await t.swappUser(userData)
        }
    },

    async mounted() {
        const t = this

        await t.getVisitorsCollectionData()
    }
}
</script>

<style scoped></style>
