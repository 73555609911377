<template>
	<div class="actionTitle-container shadow">

		<v-hover v-slot="{hover}">
			<div class="actionTitle-content-container">

				<app-text class="text-center" :color="hover && 'white'" size="large">{{ title }}</app-text>

				<app-icon :color="hover ? 'white' : 'primary'" :icon="icon" :size="computedIconSize"/>

				<v-img :src="image" :width="computedIconSize"/>

			</div>
		</v-hover>

	</div>
</template>

<script>
export default {

	name: "ActionTile",

	props: ['icon', 'image', 'title'],

	computed: {

		/**
		 * Computed Icon Size
		 *
		 * The icon should be 30vw, but the max size should be 400px.
		 */
		computedIconSize() {
			let screenWidth = this.$vuetify.breakpoint.width
			let iconSize

			iconSize = screenWidth * 0.3

			if (iconSize > 384) iconSize = 384

			return iconSize
		}
	}

}
</script>

<style scoped>
.actionTitle-container {
	display: flex;
	align-items: center;
	justify-content: center;

	aspect-ratio: 1/1;
	background: white;
	border: 1px solid lightgrey;
	border-radius: 16px;
	margin: 16px;
	padding: 16px;

	transition: 0.3s;
}

.actionTitle-container:hover {
	background: var(--v-primary-base);
}

.actionTitle-content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>
