<template>
	<div class="centerInPage text-center pa-4">

		<!--Loader-->
		<div v-if="isPageLoading">
			<app-text color="primary" size="large">Loading...</app-text>
		</div>

		<!--Password Entry-->
		<div v-if="!isUnlocked && !isPageLoading" style="width: 100%; max-width: 343px">

			<!--Instruction-->
			<app-text size="normal-bold">Please enter a Password to continue</app-text>

			<!--Password-->
			<v-text-field @click:append="isPasswordVisible = !isPasswordVisible"
						  :append-icon="isPasswordVisible ? 'icons8-eye' : 'icons8-closed-eye'"
						  autocomplete="off"
						  background-color="white"
						  class="rounded-lg mt-4"
						  :error="errors.password"
						  :error-messages="errors.passwordErrorMessage"
						  filled
						  hide-details="auto"
						  label="Password"
						  name="yyy"
						  outlined
						  prepend-inner-icon="icons8-password"
						  :type="isPasswordVisible ? 'text' : 'password'"
						  value=""
						  v-model.trim="password"/>

			<!--Submit Button-->
			<app-btn @click.native="validate"
					 :block="true"
					 class="mt-4"
					 label="Submit"/>

			<!--App Version Number-->
			<app-text class="mt-4" color="grey9">App Version - v{{appVersion}}</app-text>

		</div>

		<!--Site | Location-->
		<div v-if="isUnlocked && !isPageLoading" style="width: 100%; max-width: 343px">

			<!--Instruction-->
			<app-text size="normal-bold">Set the Site and Location for this device</app-text>
			<app-text size="small">These will be saved on this device until they are changed</app-text>

			<!--Sites | Locations-->
			<div class="mt-4">

				<!--Sites-->
				<app-input input-type="select"
						   :errors="errors.site"
						   :error-messages="errors.siteErrorMessage"
						   :items="computedSitesData.sitesData"
						   item-text="siteName"
						   label="Select a Site"
						   :return-object="true"
						   v-model="selectedSite"/>

				<!--Locations-->
				<app-input v-if="selectedSite.id"
						   input-type="select"
						   :errors="errors.location"
						   :error-messages="errors.locationErrorMessage"
						   class="mt-4"
						   :items="computedLocationsData"
						   item-text="locationName"
						   label="Select a Location"
						   :return-object="true"
						   v-model="selectedLocation"/>

				<!--Save Button-->
				<app-btn @click.native="saveSelection()" :block="true" class="mt-4" label="Save"/>

			</div>

		</div>

		<!--Confirmation Dialog-->
		<v-dialog max-width="512"
				  class="pa-4"
				  v-model="isConfirmationDialogVisible">

			<v-card>

				<!--Toolbar-->
				<v-toolbar color="primary">
					<app-text color="white" size="medium">All set</app-text>
					<v-spacer/>
					<close-icon @click.native="isConfirmationDialogVisible = false"/>
				</v-toolbar>

				<!--Content-->
				<div class="d-flex flex-column align-center white pa-4 rounded-lg" style="overflow: hidden">

					<!--Info Text-->
					<app-text size="medium">
						<strong>Site</strong>
						and
						<strong>Location</strong>
						set
					</app-text>

					<!--Icon - Pulsing-->
					<app-icon class="animate__animated animate__pulse animate__infinite" color="green" icon="check" size="256"/>

				</div>

			</v-card>

		</v-dialog>

	</div>
</template>

<script>
export default {

	name: "Admin",

	data: () => ({
		errors: {
			location: false,
			locationErrorMessage: '',
			password: false,
			passwordErrorMessage: '',
			site: false,
			siteErrorMessage: '',
		},
		isConfirmationDialogVisible: false,
		isPasswordVisible: false,
		isUnlocked: false,
		password: '',
		selectedLocation: {},
		selectedSite: {},
		isPageLoading: false,

		// Current Data
		locationsData: [],
		sitesData: [],

		// Data
		externalSitesCollectionData: [],
		locationsCollectionData: [],
		sitesCollectionData: [],
	}),

	computed: {

		/**
		 * Computed Locations Data
		 *
		 * Get all the locations for the selected site and return them as an array.
		 *
		 * @returns {Array} all locations for the selected site
		 */
		computedLocationsData() {
			const t = this

			if (t.selectedSite.id) return t.computedSitesData.locationsData.filter(item => item.site.id === t.selectedSite.id)
		},

		/**
		 * Computed Sites Data
		 *
		 * Collate all sites data into a single array.
		 * Remove any deleted data, and return sites and locations arrays.
		 *
		 * @returns {{locationsData: *[], sitesData: *[]}} an object containing sites and locations dat arrays
		 */
		computedSitesData() {
			const t = this
			const EXTERNAL_SITES_DATA = t.externalSitesCollectionData.filter(item => item.siteStatus === 'In Progress')
			const LOCATIONS_DATA = t.locationsCollectionData
			const SITES_DATA = t.sitesCollectionData
			let locationsData = []
			let sitesData = []

			// Collate all sites data
			sitesData = [...SITES_DATA, ...EXTERNAL_SITES_DATA]

			// Remove deleted documents
			sitesData = sitesData.filter(item => !item.delete)
			locationsData = LOCATIONS_DATA.filter(item => !item.delete)

			return {sitesData, locationsData}
		}

	},

	methods: {

		/**
		 * Clear Errors and Messages
		 *
		 * Clear all the errors and error messages from the inputs.
		 */
		clearErrorsAndMessages() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		/**
		 * Init
		 *
		 * Initialise the page by loading all the required sites and locations data.
		 * NB. External Sites don't have a siteName field, so add one for ease of use.
		 *
		 * @returns {Promise<void>}
		 */
		async init() {
			const t = this

			// Get Sites data
			t.sitesCollectionData = await t.MIX_firestore_getCollectionData('sites')

			// Get External Sites data, and add a siteName field for ease of use
			t.externalSitesCollectionData = await t.MIX_firestore_getCollectionData('externalSites')
			t.externalSitesCollectionData.forEach(item => item.siteName = item.projectTitle)

			// Get Locations data
			t.locationsCollectionData = await t.MIX_firestore_getCollectionData('locations')

			t.isPageLoading = false
		},

		/**
		 * Save Selection
		 *
		 * Save the current site and location data to local storage.
		 */
		saveSelection() {
			const t = this

			// If validation has failed, don't proceed
			if (!t.validate()) return

			// Add the new values to the local storage
			t.MIX_addToLocalStorage('currentSite', t.selectedSite)
			t.MIX_addToLocalStorage('currentLocation', t.selectedLocation)

			// Open dialog box
			t.isConfirmationDialogVisible = true
		},

		/**
		 * Validate
		 *
		 * Check for the presence, and validity of the required fields.
		 * If the fields are valid, continue.
		 * If the fields are NOT valid, display a field error.
		 */
		validate() {
			const t = this

			t.clearErrorsAndMessages()

			// Password
			if (t.password === 'ReverseSwappPassword') {
				t.errors.password = false
				t.errors.passwordErrorMessage = ''
				t.isUnlocked = true
			} else {
				t.errors.password = true
				t.errors.passwordErrorMessage = 'Password not accepted'
			}

			// Selected Site
			if (!t.selectedSite.id) {
				t.errors.site = true
				t.errors.siteErrorMessage = 'A site is required'
			}

			// Selected Location
			if (!t.selectedLocation.id) {
				t.errors.location = true
				t.errors.locationErrorMessage = 'A location is required'
			}

			// Return a boolean (If there are any true values, or not)
			return !Object.values(t.errors).includes(true)
		},

	},

	mounted() {
		const t = this

		t.isPageLoading = true

		// If the site/location data exists in local storage, assign it to the data
		if (t.MIX_getFromLocalStorage('currentSite')) t.selectedSite = t.MIX_getFromLocalStorage('currentSite')
		if (t.MIX_getFromLocalStorage('currentLocation')) t.selectedLocation = t.MIX_getFromLocalStorage('currentLocation')

		// Initialise the page
		t.init()
	}

}
</script>

<style scoped>

</style>
