<template>
	<v-app id="app">

		<!--App Bar (Home Icon | Company Logo | Settings Icon)-->
		<v-app-bar app
				   color="primary"
				   height="64">

			<!--Home Icon-->
			<app-icon @click.native="MIX_go('Home')" color="white" icon="home" size="24"/>

			<!--Company logo-->
			<v-img contain
				   class="mx-4"
				   max-height="32"
				   :src="companyLogo"/>

			<!--Settings Icon-->
			<app-icon @click.native="MIX_go('Settings')" color="white" icon="settings" size="24"/>

		</v-app-bar>

		<!--Page Content-->
		<v-main class="appBackground">
			<transition name="custom-classes-transition"
						enter-active-class="animate__animated animate__zoomIn animate__faster"
						leave-active-class="animate__animated animate__zoomOut animate__faster" mode="out-in">
				<router-view/>
			</transition>
		</v-main>

		<!--Logo-->
		<div v-if="!['VisitorRegistration', 'VisitorSignOut'].includes($route.name)"
			 class="d-flex justify-center">
		<v-img :src="appBackground"
			   class="px-4"
			   contain
			   style="position: absolute; bottom: 16px; opacity: 0.25"/>
		</div>

	</v-app>
</template>

<script>
import {version} from '../package.json'

export default {

	name: 'App',

	mounted() {
		console.log('app version: ', version)
	}

};
</script>

<style>
.centerInPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: absolute;
	height: calc(100vh - 128px);
	width: calc(100vw);
}
.shadow {
	box-shadow: 0 0 8px grey;
}
</style>
