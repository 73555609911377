<template>
    <div v-if="MIX_getFromLocalStorage('currentSite')" class="d-flex flex-column align-center pa-4" style="height: calc(100vh - 64px)">
        <page-title class="mt-4" title="Welcome to WIN" />

        <app-text class="d-flex justify-center mt-8" size="normal">
            Select your name from the list below if you have previously registered with us, or fill in the form to register and sign in.
        </app-text>

        <v-divider class="mt-4" style="width: 100%" />

        <!--Visitors Selection-->
        <div class="mt-4" style="width: 100%">
            <!--UserName-->
            <v-autocomplete
                class="rounded-lg"
                color="primary"
                flat
                hide-details="auto"
                item-color="primary"
                :items="visitorsCollectionData"
                item-text="userName"
                label="Select Your Name"
                no-data-text="There are no visitors to sign in."
                outlined
                :return-object="true"
                solo
                v-model="selectedUserData"
            >
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <app-text>{{ item.userName }}</app-text>
                        <app-text color="grey" size="small">{{ item.userCompany }}</app-text>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </div>

        <!--Select User Button-->
        <div class="d-flex flex-column" style="width: 100%">
            <!--Visitor Details-->
            <div v-if="selectedUserData?.id" class="mt-4">
                <!--Name-->
                <div class="d-flex align-center">
                    <app-text size="small" class="text-right mr-4" color="grey9" style="width: 80px">Name:</app-text>
                    <app-text>{{ selectedUserData.userName }}</app-text>
                </div>

                <!--Company-->
                <div class="d-flex align-center">
                    <app-text size="small" class="text-right mr-4" color="grey9" style="width: 80px">Company:</app-text>
                    <app-text>{{ selectedUserData.userCompany }}</app-text>
                </div>

                <!--Car Registration Number-->
                <div class="d-flex align-center">
                    <app-text size="small" class="text-right mr-4" color="grey9" style="width: 80px">Car Reg:</app-text>
                    <app-text>{{ selectedUserData.userCarRegistrationNumber }}</app-text>
                </div>
            </div>

            <app-btn
                v-if="selectedUserData?.id"
                @click.native="handleSelectedUser"
                class="mt-4"
                :disabled="!selectedUserData?.id"
                icon="check"
                label="This is me"
            />

            <!--Person Visiting-->
            <app-input
                v-if="isPersonVisitingVisible"
                input-type="textInput"
                class="mt-4"
                :error="errors.userPersonVisiting"
                :error-messages="errors.userPersonVisitingErrorMessage"
                label="Person Visiting"
                v-model.trim="form.userPersonVisiting"
            />
        </div>

        <!--Registration Form -------------------------------------------------------------------------------------- -->

        <div v-if="isNotInListVisible">
            <!-- OR - divider-->
            <div class="d-flex mt-4" style="width: 100%">
                <v-divider class="mt-4" style="width: 100%" />
                <app-text class="mt-1 mx-4">or</app-text>
                <v-divider class="mt-4" style="width: 100%" />
            </div>

            <!--Not in the list Button-->
            <div class="d-flex justify-center" style="width: 100%">
                <app-btn @click.native="handleNewUser" class="mt-4" icon="close" label="I'm not in the list" />
            </div>
        </div>

        <!--Form-->
        <div v-if="isRegistrationFormVisible" class="mt-4" style="width: 100%">
            <!--UserName-->
            <app-input
                input-type="textInput"
                @blur="convertCaseType('userName', 'titleCase')"
                :error="errors.userName"
                :error-messages="errors.userNameErrorMessage"
                label="Full Name"
                v-model.trim="form.userName"
            />

            <!--Company-->
            <app-input
                input-type="textInput"
                @blur="convertCaseType('userCompany', 'titleCase')"
                class="mt-4"
                :error="errors.userCompany"
                :error-messages="errors.userCompanyErrorMessage"
                label="Company"
                v-model.trim="form.userCompany"
            />

            <!--Registration Number-->
            <app-input
                input-type="textInput"
                @blur="convertCaseType('userCarRegistrationNumber', 'upperCase')"
                class="mt-4"
                :error="errors.userCarRegistrationNumber"
                :error-messages="errors.userCarRegistrationNumberErrorMessage"
                label="Car Registration Number"
                v-model.trim="form.userCarRegistrationNumber"
            />

            <!--Person Visiting-->
            <app-input
                input-type="textInput"
                @blur="convertCaseType('userPersonVisiting', 'titleCase')"
                class="mt-4"
                :error="errors.userPersonVisiting"
                :error-messages="errors.userPersonVisitingErrorMessage"
                label="Person Visiting"
                v-model.trim="form.userPersonVisiting"
            />

            <!--Privacy Policy-->
            <v-checkbox
                hide-details="auto"
                :error="errors.privacyPolicyConsent"
                :error-messages="errors.privacyPolicyConsentErrorMessage"
                label="I accept the Terms and Conditions and Privacy Policy"
                v-model="form.privacyPolicyConsent"
            />
        </div>

        <v-spacer />

        <!--Sign In Button-->
        <div v-if="computedIsReadyToSignIn" style="width: 100%">
            <app-btn @click.native="handleSignIn" :block="true" class="mt-4" color="green" label="Sign In" />
        </div>
        <div style="width: 100%">
            <app-btn @click.native="handleBack" :block="true" class="mt-4" color="grey" label="Back" />
        </div>

        <!--Dialogs ------------------------------------------------------------------------------------------------ -->

        <!--Error Dialog-->
        <v-dialog max-width="512" v-model="isErrorDialogVisible">
            <error-dialog @closeErrorDialog="isErrorDialogVisible = false" :error="error" />
        </v-dialog>

        <!--Existing User Dialog-->
        <v-dialog max-width="512" v-model="isExistingUserDialogVisible">
            <div class="white rounded-lg">
                <v-toolbar class="rounded-t-lg pl-2" color="primary">
                    <app-text color="white" size="medium">User Already Exists</app-text>
                    <v-spacer />
                    <close-icon @click.native="isExistingUserDialogVisible = false" />
                </v-toolbar>

                <div class="pa-4">
                    <app-text
                        >There is already an account with the name <b>{{ form.userName }}</b
                        >.</app-text
                    >
                    <v-divider class="my-4" />
                    <app-text size="normal-bold">{{ selectedUserData.userName }}</app-text>
                    <app-text>{{ selectedUserData.userCompany }}</app-text>
                    <v-divider class="my-4" />
                    <app-text>...is this you?</app-text>

                    <!--Action Buttons-->
                    <div class="d-flex justify-space-between">
                        <app-btn @click.native="handleSelectedUser" class="mt-4" color="green" icon="check" label="Yes, this is me" />
                        <app-btn @click.native="handleNewUser" class="mt-4" color="red" icon="close" label="No, I'm a new visitor" />
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'VisitorRegistration',

    data: () => ({
        error: '',
        errors: {
            privacyPolicyConsent: false,
            privacyPolicyConsentErrorMessage: '',
            userCarRegistrationNumber: false,
            userCarRegistrationNumberErrorMessage: '',
            userCompany: false,
            userCompanyErrorMessage: '',
            userPersonVisiting: false,
            userPersonVisitingErrorMessage: '',
            userName: false,
            userNameErrorMessage: ''
        },
        form: {
            id: '',
            userName: '',
            userCompany: '',
            userCarRegistrationNumber: '',
            userPersonVisiting: '',

            privacyPolicyConsent: false,
            userCanUsePersonalDetails: true,
            userStatus: 'APPROVED',
            userType: 'Visitor',
            userRole: 'User',
            userLevel: 'Visitor-User',

            lastSite: {},
            lastLocation: {},
            swappMethod: 'Reverse',
            swappStatus: 0,
            swappTime: ''
        },
        isErrorDialogVisible: false,
        isExistingUserDialogVisible: false,
        isRegistrationFormVisible: false,
        selectedUserData: {},
        isPersonVisitingVisible: false,
        isNotInListVisible: true,
        originalForm: {},

        // Data
        visitorsCollectionData: []
    }),

    computed: {
        computedIsReadyToSignIn() {
            const t = this
            return (
                t.form.userName && t.form.userCompany && t.form.userCarRegistrationNumber && t.form.userPersonVisiting && t.form.privacyPolicyConsent
            )
        }
    },

    methods: {
        /**
         * Convert Case Type
         *
         * Convert the text in the form field to the specified case type.
         *
         * @param field the field to convert
         * @param caseType the case type to convert to
         */
        convertCaseType(field, caseType) {
            const t = this

            if (t.form[field]) t.form[field] = t.MIX_transformText(t.form[field], caseType)
        },

        checkForExistingAccount(form) {
            const t = this
            let existingUser = false

            const EXISTING_USER = t.visitorsCollectionData.find((user) => user.userName.toUpperCase() === form.userName.toUpperCase())

            if (EXISTING_USER) {
                t.toggleExistingUserDialogVisibility()
                t.selectedUserData = EXISTING_USER
                existingUser = true
            }

            return existingUser
        },

        /**
         * Clear Errors and Messages
         *
         * Clear all the errors and error messages from the inputs.
         */
        clearErrorsAndMessages() {
            const t = this

            for (const error in t.errors) {
                if (typeof t.errors[error] === 'string') t.errors[error] = ''
                if (typeof t.errors[error] === 'boolean') t.errors[error] = false
            }
        },

        /**
         * Create Document
         *
         * Add the missing form fields, and create a new Firebase User document.
         * If there are no errors, SWAPP the user In.
         *
         * @returns {Promise<void>}
         */
        async createDocument() {
            const t = this
            let form = t.form

            const isExistingUser = t.checkForExistingAccount(form)
            if (isExistingUser) return

            form.id = t.MIX_generateId()

            const RESPONSE = await t.MIX_firestore_createDocument('visitors', form.id, form)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error creating document: ', RESPONSE.error)
                t.handleError('errorCreatingDoc')
                return
            }

            await t.swappUser(form)
        },

        /**
         * Get Collection Data
         *
         * Fetch the data from the collection and assign them to the class state.
         *
         * @returns {Promise<void>}
         */
        async getVisitorsCollectionData() {
            const t = this
            let collectionData = []

            await t.$firebase.db
                .collection('visitors')
                .where('swappStatus', '==', 0)
                .onSnapshot((snapshot) => {
                    // Rest array to avoid data duplication
                    collectionData = []

                    snapshot.forEach((doc) => {
                        const DOCUMENT = doc.data()
                        DOCUMENT.id = doc.id

                        if (!DOCUMENT.hasOwnProperty('delete')) collectionData.push(DOCUMENT)
                    })

                    t.visitorsCollectionData = collectionData.sort((a, b) => (a.userName > b.userName ? 1 : -1))
                })
        },

        handleCancelButton() {
            const t = this
            t.MIX_go('Home')
        },
        handleBack() {
            const t = this

            t.MIX_go('SwappDirection')
        },

        /**
         * Handle Error
         *
         * Open the error dialog and display the current error.
         *
         * @param error the error code to display
         */
        handleError(error) {
            const t = this

            t.isErrorDialogVisible = true
            t.error = error
        },

        /**
         * Handle New User
         *
         * Clear the form and errors to allow a new user to register.
         */
        handleNewUser() {
            const t = this

            t.isRegistrationFormVisible = true
            t.clearErrorsAndMessages()
            t.form = JSON.parse(t.originalForm)
            t.selectedUserData = {}
            t.isExistingUserDialogVisible = false
        },

        /**
         * Handle Selected User
         *
         * Clear the errors and set the form data with the selected user's data.
         */
        handleSelectedUser() {
            const t = this

            t.form = t.selectedUserData
            t.isNotInListVisible = false
            t.form.userPersonVisiting = ''
            t.isPersonVisitingVisible = true
            t.isRegistrationFormVisible = false
            t.isExistingUserDialogVisible = false

            t.clearErrorsAndMessages()
        },

        /**
         * Handle Sign In
         *
         * If the form has passed validated, either create or update the Firebase document.
         *
         * @returns {Promise<void>}
         */
        async handleSignIn() {
            const t = this

            if (!t.validateForm()) return

            // Create
            if (!t.form?.id) await t.createDocument()

            // Update
            if (t.form?.id) await t.updateDocument()
        },

        /**
         * SWAPP User
         *
         * SWAPP the current user with their ID and swappDirection.
         *
         * @returns {Promise<void>}
         * @param userData the user data to SWAPP
         */
        async swappUser(userData) {
            const t = this

            // Convert the word-based direction for the 1/0 database vale
            let swappDirection = t.MIX_getFromLocalStorage('swappDirection')
            swappDirection = swappDirection.toUpperCase() === 'IN' ? 1 : 0

            const RESPONSE = await t.MIX_swapp(userData.id, userData.userType, swappDirection)

            // Set the params with the SWAPP result (true or false), and go to the SWAPP Result page
            t.MIX_go('SwappResult', { hasErrors: RESPONSE.hasErrors })
        },

        toggleExistingUserDialogVisibility() {
            const t = this
            t.isExistingUserDialogVisible = !t.isExistingUserDialogVisible
        },

        /**
         * Update Document
         *
         * Update the document with the new form data.
         *
         * @returns {Promise<void>}
         */
        async updateDocument() {
            const t = this
            let form = t.form

            const RESPONSE = await t.MIX_firestore_updateDocumentFields('visitors', form.id, form)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error updating document: ', RESPONSE.error)
                t.handleError('errorUpdatingDoc')
                return
            }

            await t.swappUser(form)
        },

        /**
         * Validate Form
         *
         * Check the form for any errors before saving.
         *
         * @returns {boolean} boolean whether the form has passed, or not
         */
        validateForm() {
            const t = this

            t.clearErrorsAndMessages()

            // UserName
            if (!t.form.userName) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'What is your name?'
            }

            // User Company
            if (!t.form.userCompany) {
                t.errors.userCompany = true
                t.errors.userCompanyErrorMessage = 'What company are you from?'
            }

            // Car Registration Number
            if (!t.form.userCarRegistrationNumber) {
                t.errors.userCarRegistrationNumber = true
                t.errors.userCarRegistrationNumberErrorMessage = 'What is your car registration number?'
            }

            // Person Visiting
            if (!t.form.userPersonVisiting) {
                t.errors.userPersonVisiting = true
                t.errors.userPersonVisitingErrorMessage = 'Who are you visiting?'
            }

            // Privacy
            if (!t.form.privacyPolicyConsent) {
                t.errors.privacyPolicyConsent = true
                t.errors.privacyPolicyConsentErrorMessage = 'You must accept the Terms and Conditions and Privacy Policy'
            }

            return !Object.values(t.errors).includes(true)
        }
    },

    async mounted() {
        const t = this

        t.originalForm = JSON.stringify(t.form)

        await t.getVisitorsCollectionData()
    }
}
</script>

<style scoped></style>
