<template>
	<div class="d-flex flex-column align-center pa-4">

		<!--Information Text - no errors SWAPPing -->
		<app-text v-if="!hasSwappErrors" class="mt-4" size="medium">You are now SWAPPed</app-text>
		<app-text v-if="!hasSwappErrors" class="mt-4" size="xlarge-bold">{{ swappDirection }}</app-text>

		<!--Information Text - errors with SWAPPing -->
		<app-text v-if="hasSwappErrors" size="medium">
			There was a problem SWAPPing you
			<span class="font-weight-bold">{{ swappDirection }}</span>,
			please try again
		</app-text>

		<!--SWAPP Icon - result based-->
		<app-icon :class="getQrStyle().animateClass"
				  :color="getQrStyle().colorClass"
				  icon="qrCode"
				  size="256"/>

		<!--Instruction-->
		<app-text class="mt-4">
			You will be automatically redirected to the home page in {{ secondsRemaining }} seconds
		</app-text>

		<!--Done Button-->
		<div class="d-flex flex-column align-center mt-4">

			<app-text color="grey9">
				(...or click the Done button below)
			</app-text>

			<app-btn @click.native="redirectToHomePage" :block="true" class="mt-8" color="primary" label="Done"/>

		</div>

	</div>
</template>

<script>
export default {

	name: "SwappResult",

	data: () => ({
		secondsRemaining: 5,
		hasSwappErrors: null,
		swappDirection: '',
	}),

	methods: {

		/**
		 * Get QR Style
		 *
		 * Returns the QR code style based on direction and errors.
		 *  - If hasSwappErrors, flash red.
		 *  - If hasSwappErrors is false and swappDirection is 'IN', pulse green.
		 *  - If hasSwappErrors is false and swappDirection is 'OUT', pulse red.
		 */
		getQrStyle() {
			const t = this
			let animateClass
			let colorClass

			// If there are any errors, the QR Code should flash red
			if (t.hasSwappErrors) {
				animateClass = 'animate__animated animate__flash animate__infinite'
				colorClass = 'red'
			}

			// If there are no errors...
			else {

				// ...and the SWAPP Direction is IN, the QR Code should pulse green
				if (t.swappDirection.toUpperCase() === 'IN') {
					animateClass = 'animate__animated animate__pulse animate__infinite'
					colorClass = 'green'
				}
				// ...and the SWAPP Direction is OUT, the QR Code should pulse red
				else {
					animateClass = 'animate__animated animate__pulse animate__infinite'
					colorClass = 'red'
				}
			}

			return {animateClass, colorClass}
		},

		/**
		 * Redirect To Home Page
		 *
		 * Redirect the user to the Home page and log them out.
		 * NB. SWAPPing with a QR code doesn't sign them in, but manually does.
		 */
		redirectToHomePage() {
			const t = this

			t.MIX_auth_logOut()
		},

		/**
		 * Start Count Down Timer
		 *
		 * Sets a timer, redirecting to the Home page when complete (if the Done button hasn't been pressed).
		 */
		startCountdownTimer() {
			const t = this
			let seconds = t.secondsRemaining

			let counter = setInterval(async () => {

				if (seconds === 0) {
					clearInterval(counter)
					t.redirectToHomePage()
				} else {
					seconds--
					t.secondsRemaining = seconds
				}

			}, 1000)
		},

	},

	created() {
		const t = this

		// Set the swapp result data, either true or false
		t.hasSwappErrors = t.$route.params.data.hasErrors
		t.swappDirection = t.MIX_getFromLocalStorage('swappDirection')

		t.startCountdownTimer()
	}

}
</script>

<style scoped>

</style>
