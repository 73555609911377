<template>
    <div class="d-flex flex-column align-center">
        <!--No site error message-->
        <div v-if="!MIX_getFromLocalStorage('currentSite')" class="centerInPage text-center px-4">
            <app-text color="red" size="normal-bold"> No Site or Location has been set up for this device </app-text>
            <app-text>Please go to Settings to add these</app-text>
        </div>

        <!--Site | Location-->
        <div v-if="MIX_getFromLocalStorage('currentSite')" class="d-flex flex-column align-center mt-4">
            <app-text class="text-center" color="greyD" size="large">
                {{ MIX_getFromLocalStorage('currentSite').siteName }}
            </app-text>
            <app-text class="text-center" color="greyD" size="medium">
                {{ MIX_getFromLocalStorage('currentLocation').locationName }}
            </app-text>
        </div>

        <page-title class="mt-4" title="What do you want to do?" />

        <!--Action Tiles-->
        <div v-if="MIX_getFromLocalStorage('currentSite')" class="centerInPage">
            <div class="d-flex">
                <!--Sign In-->
                <action-tile @click.native="handleSignIn" icon="logIn" title="Sign In" />

                <!--Sign Out-->
                <action-tile @click.native="handleSignOut" icon="logOut" title="Sign Out" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',

    methods: {
        handleSignIn() {
            const t = this

            t.MIX_addToLocalStorage('swappDirection', 'IN')

            t.MIX_go('VisitorRegistration')
        },

        handleSignOut() {
            const t = this

            t.MIX_addToLocalStorage('swappDirection', 'OUT')

            t.MIX_go('VisitorSignOut')
        }
    }
}
</script>
