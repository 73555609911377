<template>
	<div class="d-flex flex-column align-center">

		<page-title title="Who are you?"/>

		<!--Action Tiles-->
		<div v-if="MIX_getFromLocalStorage('currentSite')"
			 class="centerInPage">

			<div class="d-flex">

				<!--Staff-->
				<action-tile @click.native="MIX_go('SwappMethod')"
							 :image="require('@/assets/images/re-swapp-staff-icon.png')"
							 title="Staff"/>

				<!--Visitor-->
				<action-tile @click.native="handleVisitor"
							 :image="require('@/assets/images/re-swapp-visitor-icon.png')"
							 title="Visitor"/>

			</div>

		</div>

	</div>
</template>

<script>
export default {

	name: 'Home',

	methods: {

		/**
		 * Handle Visitor
		 *
		 * Directs the user to the correct page based on the swapp direction.
		 */
		handleVisitor() {
			const t = this
			const SWAPP_DIRECTION = t.MIX_getFromLocalStorage('swappDirection')

			if (SWAPP_DIRECTION.toUpperCase() === 'IN') t.MIX_go('VisitorRegistration')

			if (SWAPP_DIRECTION.toUpperCase() === 'OUT') t.MIX_go('VisitorSignOut')
		},

	},

}
</script>
