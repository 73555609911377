<template>

	<v-btn :block="block"
		   class="appButton-container"
		   :color="color || 'primary'"
		   :dark="!disabled"
		   depressed
		   :disabled="disabled"
		   :height="height || 48"
		   :loading="loading">

		<app-icon v-if="icon" class="mr-2" :icon="icon" :size="iconSize" :color="iconColor"/>

		{{ label }}

	</v-btn>

</template>

<script>
export default {

	name: "AppBtn",

	props: [
		'block',
		'color',
		'disabled',
		'height',
		'icon',
		'iconColor',
		'iconSize',
		'label',
		'loading',
	],

}
</script>

<style scoped>
.appButton-container {
	border-radius: 8px;
	box-shadow: 0 0 0 1px white;
}
</style>
